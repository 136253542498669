/* Navbar.css */
:root {
  --navbar-height: 70px;
}

.navbar {
  background: rgba(18, 18, 18, 1);
  height: var(--navbar-height);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.3rem;
  position: sticky;
  top: 0;
  z-index: 999;
  padding: 4px 0;
}

.navbar-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1500px;
  padding: 0 20px;
}

.navbar-logo {
  color: var(--color-main);
  text-decoration: none;
  text-shadow: 2px 2px rgba(128, 128, 128, 0.8);
  font-size: 1.9rem;
  font-family: 'Futura', sans-serif;
}

.navbar-motto {
  font-family: var(--font-motto);
  color: #ffffff;
  font-size: 1.2rem;
  font-style: italic;
  text-align: center;
  width: 100%;
  margin-top: 5px;
}

.navbar-logo-container {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-grow: 1;
}

.navbar-text-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.navbar-logo-image {
  height: 50px;
}

.nav-menu {
  display: flex;
  list-style: none;
  text-align: center;
  margin: 0;
  padding: 0;
}

.nav-item {
  height: var(--navbar-height);
}

.nav-links {
  color: var(--color-main);
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0rem 2rem;
  padding-right: 2rem;
  height: 100%;
  font-family: 'Avenir', 'Futura', sans-serif;
}

.nav-links:hover {
  border-bottom: 4px solid var(--color-main);
  transition: all 0.2s ease-out;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  .navbar-container {
    justify-content: space-between;
    flex-wrap: nowrap;
  }

  .navbar-logo-container {
    flex-basis: auto;
    justify-content: flex-start;
    margin-bottom: 0;
    max-width: calc(100% - 50px); /* Adjust based on menu icon size */
  }

  .navbar-text-container {
    align-items: flex-start;
  }

  .navbar-logo {
    font-size: 1.5rem;
  }

  .navbar-motto {
    display: block;
    font-size: 0.9rem;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 100%; /* Changed from var(--navbar-height) */
    left: -100%;
    opacity: 0;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #242222;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-item {
    height: var(--navbar-height);
  }

  .nav-links {
    text-align: center;
    padding: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: var(--navbar-height);
    border-top: 1px solid rgba(255, 255, 255, 0.2);
  }

  .nav-links:hover {
    background-color: var(--color-main);
    color: #242424;
    border-bottom: none;
  }

  .menu-icon {
    display: block;
    font-size: 1.8rem;
    cursor: pointer;
    color: var(--color-main);
  }

  .navbar-logo-image {
    height: 40px;
  }
}