.container {
    width: 100vw;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
  }
  
  .animated-section {
    width: 100vw;
    padding: 0;
    opacity: .8;
    transform: translateY(0px);
    transition: opacity 1.8s ease-out, transform 0.8s ease-out;
  }
  
  .animated-section.visible {
    opacity: 1;
    transform: translateY(0);
  }
  
  .staggered-element {
    opacity: 0;
    transform: translateX(-100vw);
    transition: opacity 3s ease-out, transform 2s ease-out;
  }
  
  .visible .staggered-element {
    opacity: 1;
    transform: translateX(0);
  }
  
  .component-wrapper {
    width: 100vw;
    margin: 0;
    padding: 20px 0;
    background-color: white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .component-content {
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  @media (max-width: 1440px) {
    .component-content {
      max-width: 100%;
    }
  }
  
  @media (max-width: 768px) {
    .component-wrapper {
      padding: 10px 0;
    }
    .component-content {
      padding: 0 10px;
    }
  }