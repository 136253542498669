.team-container {
  background-color: #ffffff;
  padding: 3% 5%;
  text-align: center;
  background-size: 80%;
  background-repeat: no-repeat;
  background-position: center 45%;
}

.team-title {
  font-size: 36px;
  font-family: 'Futura', sans-serif;
  margin: 1% 20% 3% 20%;
}

.team-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
  max-width: 1000px;
  margin: 0 auto;
}

.team-member {
  flex: 0 0 calc(33.333% - 20px);
  max-width: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.team-photo-container {
  width: 200px;
  height: 200px;
  position: relative;
  overflow: hidden;
  border-radius: 0px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
}

.team-photo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.team-info {
  margin-top: 12px;
  width: 100%;
}

.team-name {
  font-size: 20px;
  font-family: 'Futura', sans-serif;
  font-weight: 500;
}

.team-position {
  font-size: 16px;
  color: #666;
  margin-top: 4px;
}

@media (max-width: 900px) {
  .team-member {
    flex: 0 0 calc(50% - 15px);
  }

  .team-photo-container {
    width: 180px;
    height: 180px;
  }

  .team-name {
    font-size: 18px;
  }

  .team-position {
    font-size: 14px;
  }
}

@media (max-width: 600px) {
  .team-member {
    flex: 0 0 100%;
    max-width: 220px;
  }

  .team-photo-container {
    width: 220px;
    height: 220px;
  }

  .team-name {
    font-size: 22px;
  }

  .team-position {
    font-size: 16px;
  }
}