video {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}

.hero-container {
  height: 85vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 400px rgba(18, 18, 18, 0.8);
}

.hero-container h1 {
  color: #fff;
  font-family: 'Avenir', sans-serif;
  font-size: 70px;
  font-weight: 100;
  text-align: center;
  margin: 0 5%;
  line-height: 1.2;
}

.line {
  display: inline-block;
  overflow: hidden;
  position: relative;
  font-family: 'Avenir', sans-serif;
}

.line::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(18, 18, 18, 1);
  animation: reveal .75s cubic-bezier(0, 0, 1, 1) forwards;
}

.line1::after {
  animation-delay: 1.25s;
}

.line2::after {
  animation-delay: 2s;
}

.line3::after {
  animation: reveal 1.75s cubic-bezier(0, 0, 1, 1) forwards; /* Longer duration for line 3 */
  animation-delay: 2.25s;
}

@keyframes reveal {
  0% {
    width: 100%;
    left: 0;
  }
  100% {
    width: 0;
    left: 100%;
  }
}

.hero-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 60px;
}

.hero-inner > h2 {
  color: #fff;
  font-size: 20px;
  font-family: 'Avenir', sans-serif;
  font-weight: normal;
  line-height: 1.5;
  margin: 40px 0%;
  opacity: 0;
  transform: translateY(40px);
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.hero-inner > h2.visible {
  opacity: 1;
  transform: translateY(0);
}

/* Large screen styles */
@media screen and (min-width: 961px) {
  .line1, .line2 {
    display: inline-block;
  }
  .line2 {
    margin-left: 0.25em;  /* Add space between 'Knowledge and' and 'Data Analytics' */
  }
  .line3 {
    display: block;
    margin-top: 0.2em;  /* Add slight space above 'for Policy' */
  }
}

/* Small screen styles */
@media screen and (max-width: 960px) {
  .hero-container {
    height: auto;
    padding: 20px 0;
  }

  .hero-container h1 {
    font-size: 40px;
  }

  .line {
    display: block;
  }

  .line2 {
    margin-top: 0.3em;  /* Add space between 'Knowledge and' and 'Data Analytics' */
  }

  .line3 {
    margin-top: 0.3em;  /* Add space between 'Data Analytics' and 'for Policy' */
  }

  .hero-inner h2 {
    font-size: 18px;
    margin: 20px 10%;
  }
}