:root {
  --background-color: rgba(18, 18, 18, 0.8);
  --text-color: #FFFFFF;
  --font-family-main: 'Avenir', sans-serif;
  --font-family-title: 'Futura', sans-serif;
}

.outer-container {
  background-color: var(--background-color);
  padding: 0;
}

.summary-container {
  background-color: transparent;
  padding: 30px 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.summary-image {
  max-width: 50%;
  height: auto;
  margin-bottom: 20px;
}

.summary-text {
  font-family: var(--font-family-main);
  font-size: 2em;
  color: var(--color-main);
  text-align: center;
  line-height: 1.5;
  margin: 5% 13%;
}

.summary-title {
  font-size: 3em;
  text-align: center;
  margin-bottom: 20px;
}

.title-part1 {
  color: rgb(0, 0, 0);
}

.title-part2 {
  font-family: var(--font-family-title);
  font-style: italic;
  color: var(--color-main);
}

@media screen and (max-width: 960px) {
  .summary-text {
    margin: 5% 10%;
    font-size: 1.8em;
  }
}