.background-container {
  /* position: relative;  */ 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* background-color: rgba(0, 0, 0, 0.8);  
  background-color: hsla(228, 15%, 31%, .9);
  background-color: rgba(0, 0, 0, 0.8);  */
  background-color: rgba(18, 18, 18, 0.8);
  min-height: 650px;
  text-align: justify;
  margin: 0px 0px 0px 0px; /* Combined margin properties */
}

.info-title {
  font-size: 40px;
  color: white;
  /* font-weight: bold; */
  border-bottom: 2px solid white;
  padding-bottom: 0px; /* Adds space between title and underline */
  padding-top: 0px;
  margin-bottom: 50px; /* Adds space between title and paragraphs */
  margin-top: 60px; /* Adds space between title and paragraphs */
}

.info-text {
  font-size: 24px;
  font-family: Avenir, 'Gill Sans MT', 'Calibri', 'Trebuchet MS', sans-serif;
  color: white;
  line-height: 1.5;
  padding: 2% 20% 2% 20%; /* Combined padding properties */
}

.highlighted {
  color: var(--color-main);
}

.highlighted-other {
  color: #A4D9FF;
  font-family: Avenir, 'Gill Sans MT', 'Calibri', 'Trebuchet MS', sans-serif;
}


@media screen and (max-width: 960px) {
  .info-text {
    padding: 5% 5% 5% 5%; /* Combined padding properties */
    font-size: 20px;
  }
  
  .info-image {
    max-width: 100%; /* Adjust as necessary */
  }
  
  .info-title {
    font-size: 2em; /* Adjust as necessary */
  }
}