/* Add this to your App.css */
body.no-scroll {
  overflow: hidden;
}

/* Your existing App.css styles */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

.home,
.expertise,
.team,
.sign-up,
.background {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.expertise {
  background-color: #343A49;
  background-size: cover;
  color: #343A49;
}

.background {
  background-color: black;
  color: white;
}

/* Styles for smooth transition and loader */
.app-container {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.app-container.loaded {
  opacity: 1;
}

.splash-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000; /* or any color that matches your design */
  z-index: 9998;
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.loaded .splash-screen {
  opacity: 0;
  pointer-events: none;
}

#loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent background */
}

#loader {
  border: 5px solid #f3f3f3;
  border-top: 5px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* You can uncomment these if you need them in the future */
/*.team {
  background-image: url('/images/img-1.jpg');
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #343A49;
  font-size: 100px;
}*/

/*.sign-up {
  background-image: url('/images/img-8.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #343A49;
  font-size: 100px;
}*/