.split-component-container {
  display: flex;
  width: 90%;
  overflow: hidden;
  margin: 0 auto;
  padding: 0;
  box-sizing: border-box;
  flex-direction: row;
  border-bottom: 0px solid black;
  border-top: 10px solid var(--color-main);
  aspect-ratio: 7.5 / 3;
}

.text-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: hsla(0, 0%, 100%, 1);
  width: 60%;
  padding: 2% 4%;
  height: 400px;
}

.split-title {
  font-size: 48px;
  font-family: 'Avenir', sans-serif;
  color: rgba(31, 78, 121); 
  text-align: center;
  margin-bottom: 4%;
  margin-top: 15%;
}

.split-subtitle {
  font-size: 22px;
  font-family: 'Avenir', sans-serif;
  color: rgba(31, 78, 121); 
  margin-bottom: 5%;
  text-align: center;
}

.split-text {
  font-size: 18px;
  font-family: 'Avenir', sans-serif;
  color: #000000;
  text-align: justify;
  line-height: 1.5;
  margin-bottom: 2%;
}

.image-section {
  width: 40%;
  overflow: hidden;

}

.image-section img {
  width: 100%;
  height: 100%;
 }

@media screen and (max-width: 1200px) {
  .split-component-container {
    width: 100%;
    height: 475px;
  }

  .text-section {
    padding: 2% 3%;
    padding-bottom: 5%;
  }

  .split-title {
    font-size: 42px;
    padding-top: 2%;
  }

  .split-subtitle {
    font-size: 20px;
  }

  .split-text {
    font-size: 18px;
  }
}

@media screen and (max-width: 1024px) {
  .split-component-container {
    flex-direction: column;
    min-height: auto;
    aspect-ratio: auto;
    width: 100%;
    align-items: center;
    height:900px;
  }

  .split-component-container.reverse {
    flex-direction: column-reverse;
  }

  .text-section, .image-section {
    width: 90%;
  }

  .text-section {
    padding-bottom: 5%;
    height: auto;
    min-height: 300px;
  }

  .split-title {
    font-size: 42px;
    margin-top: 5%;
  }

  .split-subtitle {
    font-size: 20px;
  }

  .split-text {
    font-size: 20px;
    padding: 0;
  }

  .image-section {
    height: auto;
    aspect-ratio: 16 / 9;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 85%;
    }
}

@media screen and (max-width: 768px) {
  .split-component-container {
    width: 100%;
    height:700px;
  }

  .split-title {
    font-size: 28px;
    margin-top: 10%;
  }

  .split-subtitle {
    font-size: 18px;
  }

  .split-text {
    font-size: 17px;
  }

  .image-section {
    width: 95%;
  }

  .text-section {
    width: 100%;
    padding-bottom: 15%;
    min-height: 400px;
  }
}