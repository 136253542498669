:root {
  --icon-size: 210px; /* Adjust this value to change the size of the squares */
}

.image-container {
  background-color: #fafafa;
  padding: 2rem;
  text-align: center;
  opacity: 0.95;
}

.image-title {
  font-size: clamp(24px, 5vw, 40px);
  padding: 5% 0 3% 0;
  font-family: 'Futura', sans-serif;
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(3, var(--icon-size));
  gap: 2rem;
  justify-content: center;
  margin: 0 auto;
}

.image-item {
  width: var(--icon-size);
  height: var(--icon-size);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.single-image {
  max-width: 80%;
  max-height: 80%;
  object-fit: contain;
}

@media (max-width: 768px) {
  .image-grid {
    grid-template-columns: repeat(2, var(--icon-size));
  }
}

@media (max-width: 480px) {
  :root {
    --icon-size: 160px; /* Optionally, reduce size on very small screens */
  }
  .image-grid {
    grid-template-columns: var(--icon-size);
  }
}