.motto-container {
  background-color: #ffffff;
  text-align: center;
  min-height: 450px;
  opacity: 0.95;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border-bottom: 2px solid black;
}

.motto-text {
  font-family: var(--font-motto);
  font-style: italic;
  font-size: 4em;
  color: var(--color-accent);
  padding: 5%;
}

.motto-explanation {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: justify;
  font-family: var(--font-explanation);
  font-size: 1.3em;
  color: var(--color-text);
  margin: 0 25% 0.2%;
  padding: 0 0 0%;
}

.motto-highlighted {
  color: var(--color-accent);
  font-weight: bold;
  font-style: italic;
}

@media screen and (max-width: 960px) {
  .motto-text {
    font-size: 3em;
    padding-top: 10%;
  }
  .motto-explanation {
    margin: 0% 5% 10% 10%;
    padding-top: 5%;
  }
}